/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AdjustmentDealRead,
  AdjustmentDealReadFromJSON,
  AdjustmentDealReadToJSON,
  ResponseFailureBody,
  ResponseFailureBodyFromJSON,
  ResponseFailureBodyToJSON,
} from '../models'

export interface GetAdjustmentDealsIdRequest {
  id: number
}

/**
 *
 */
export class AdjustmentDealsApi extends runtime.BaseAPI {
  /**
   * 取引案件（要調整）一覧を取得する
   */
  async getAdjustmentDealsRaw(): Promise<runtime.ApiResponse<Array<AdjustmentDealRead>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/adjustment_deals`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdjustmentDealReadFromJSON))
  }

  /**
   * 取引案件（要調整）一覧を取得する
   */
  async getAdjustmentDeals(): Promise<Array<AdjustmentDealRead>> {
    const response = await this.getAdjustmentDealsRaw()
    return await response.value()
  }

  /**
   * 指定IDに該当する取引案件（要調整）の情報を取得する
   */
  async getAdjustmentDealsIdRaw(
    requestParameters: GetAdjustmentDealsIdRequest
  ): Promise<runtime.ApiResponse<AdjustmentDealRead>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdjustmentDealsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['X-An-Ki-Csrf-Token'] = this.configuration.apiKey('X-An-Ki-Csrf-Token') // CSRFToken authentication
    }

    const response = await this.request({
      path: `/adjustment_deals/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => AdjustmentDealReadFromJSON(jsonValue))
  }

  /**
   * 指定IDに該当する取引案件（要調整）の情報を取得する
   */
  async getAdjustmentDealsId(requestParameters: GetAdjustmentDealsIdRequest): Promise<AdjustmentDealRead> {
    const response = await this.getAdjustmentDealsIdRaw(requestParameters)
    return await response.value()
  }
}
