/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AdjustmentDealReadGradePenalties,
  AdjustmentDealReadGradePenaltiesFromJSON,
  AdjustmentDealReadGradePenaltiesFromJSONTyped,
  AdjustmentDealReadGradePenaltiesToJSON,
  Annum,
  AnnumFromJSON,
  AnnumFromJSONTyped,
  AnnumToJSON,
  Brand,
  BrandFromJSON,
  BrandFromJSONTyped,
  BrandToJSON,
  DealReadWholesaler,
  DealReadWholesalerFromJSON,
  DealReadWholesalerFromJSONTyped,
  DealReadWholesalerToJSON,
  Grade,
  GradeFromJSON,
  GradeFromJSONTyped,
  GradeToJSON,
  PackagingForm1,
  PackagingForm1FromJSON,
  PackagingForm1FromJSONTyped,
  PackagingForm1ToJSON,
  Prefecture,
  PrefectureFromJSON,
  PrefectureFromJSONTyped,
  PrefectureToJSON,
  Region,
  RegionFromJSON,
  RegionFromJSONTyped,
  RegionToJSON,
  SpecialCondition,
  SpecialConditionFromJSON,
  SpecialConditionFromJSONTyped,
  SpecialConditionToJSON,
  StoragePeriod1,
  StoragePeriod1FromJSON,
  StoragePeriod1FromJSONTyped,
  StoragePeriod1ToJSON,
} from './'

/**
 * ディール（Read）
 * @export
 * @interface DealRead
 */
export interface DealRead {
  /**
   * ID
   * @type {number}
   * @memberof DealRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof DealRead
   */
  readonly versionNumber: number
  /**
   * ディールステータス
   *
   * * "closed": 成約済
   * * "provisional": 仮成約
   * * "not_closed": 不成約
   * * "cancelled": 取消済
   * * "done": 完了
   * @type {string}
   * @memberof DealRead
   */
  status: DealReadStatusEnum
  /**
   * オファーID
   * @type {number}
   * @memberof DealRead
   */
  readonly offerId: number | null
  /**
   * 生産者承諾状況（"waiting": 要承諾、"agreement": 承諾済、"disagreement": 不承諾）
   * @type {string}
   * @memberof DealRead
   */
  producerAgreementStatus: DealReadProducerAgreementStatusEnum
  /**
   * 卸事業者承諾状況（"waiting": 要承諾、"agreement": 承諾済、"disagreement": 不承諾）
   * @type {string}
   * @memberof DealRead
   */
  wholesalerAgreementStatus: DealReadWholesalerAgreementStatusEnum
  /**
   * WCI検査調整状況（"not_needed": なし、"waiting": 検査確認待ち、"ok": 検査確認OK、"ng": 検査確認NG）
   * @type {string}
   * @memberof DealRead
   */
  inspectionAdjustmentStatus: DealReadInspectionAdjustmentStatusEnum
  /**
   * 承諾期限日（ディール登録時と再提示時に設定される）
   * @type {Date}
   * @memberof DealRead
   */
  readonly agreementDeadline: Date
  /**
   * 再提示日時（再提示時に設定される）
   * @type {Date}
   * @memberof DealRead
   */
  readonly resubmitRequestedAt: Date | null
  /**
   *
   * @type {DealReadWholesaler}
   * @memberof DealRead
   */
  wholesaler: DealReadWholesaler
  /**
   * ディール生成日時
   * @type {Date}
   * @memberof DealRead
   */
  readonly createdAt: Date
  /**
   * 銘柄ID
   * @type {number}
   * @memberof DealRead
   */
  readonly brandId: number
  /**
   *
   * @type {Brand}
   * @memberof DealRead
   */
  brand: Brand
  /**
   * 等級ID
   * @type {number}
   * @memberof DealRead
   */
  readonly gradeId: number
  /**
   *
   * @type {Grade}
   * @memberof DealRead
   */
  grade: Grade
  /**
   * 年産ID
   * @type {number}
   * @memberof DealRead
   */
  readonly annumId: number
  /**
   *
   * @type {Annum}
   * @memberof DealRead
   */
  annum: Annum
  /**
   * 都道府県ID
   * @type {number}
   * @memberof DealRead
   */
  readonly prefectureId: number
  /**
   *
   * @type {Prefecture}
   * @memberof DealRead
   */
  prefecture: Prefecture
  /**
   * 地域ID
   * @type {number}
   * @memberof DealRead
   */
  readonly regionId: number | null
  /**
   *
   * @type {Region}
   * @memberof DealRead
   */
  region: Region | null
  /**
   * 納品物の梱包形態ID
   * @type {number}
   * @memberof DealRead
   */
  packagingFormId: number
  /**
   *
   * @type {PackagingForm1}
   * @memberof DealRead
   */
  packagingForm: PackagingForm1
  /**
   * 取引俵数
   * @type {string}
   * @memberof DealRead
   */
  readonly tradingSacks: string
  /**
   * 取引重量（kg）
   * @type {number}
   * @memberof DealRead
   */
  readonly tradingKg: number
  /**
   * 納品物の数量
   * @type {number}
   * @memberof DealRead
   */
  readonly packageCount: number
  /**
   * ディール単価（円／俵）
   * @type {number}
   * @memberof DealRead
   */
  readonly unitPrice: number
  /**
   * オファー単価（円／俵）
   * @type {number}
   * @memberof DealRead
   */
  readonly offerUnitPrice: number
  /**
   * 増額される金額（円）
   * @type {number}
   * @memberof DealRead
   */
  unitPriceAdjustmentInYen: number
  /**
   * 検査後保管可能期間ID
   * @type {number}
   * @memberof DealRead
   */
  storagePeriodId: number
  /**
   *
   * @type {StoragePeriod1}
   * @memberof DealRead
   */
  storagePeriod: StoragePeriod1
  /**
   * 取引金額（税込）
   * @type {number}
   * @memberof DealRead
   */
  readonly tradingVolumeInYen: number
  /**
   * 等級格差による減額（空可）
   * @type {Array<AdjustmentDealReadGradePenalties>}
   * @memberof DealRead
   */
  readonly gradePenalties: Array<AdjustmentDealReadGradePenalties>
  /**
   * 特別条件IDリスト（空可）
   * @type {Array<number>}
   * @memberof DealRead
   */
  readonly specialConditionIds: Array<number>
  /**
   * 特別条件リスト（空可）
   * @type {Array<SpecialCondition>}
   * @memberof DealRead
   */
  readonly specialConditions: Array<SpecialCondition>
  /**
   * 特別条件メモ
   * @type {string}
   * @memberof DealRead
   */
  readonly specialConditionsMemo: string
  /**
   * 収穫予定時期（画面では「x月y旬」として扱われる、上旬: 日付が1、中旬: 日付が11、下旬: 日付が21、nullは「未定・不明」）
   * @type {Date}
   * @memberof DealRead
   */
  expectedHarvestTimeframe: Date | null
  /**
   * 検査実施主体（"not_needed": 検査不要、"self": 自身で検査する／検査手配する、"request": 外部検査機関の手配を依頼する）
   * @type {string}
   * @memberof DealRead
   */
  inspectionResponsibility: DealReadInspectionResponsibilityEnum
}

/**
 * @export
 * @enum {string}
 */
export enum DealReadStatusEnum {
  Closed = 'closed',
  Provisional = 'provisional',
  NotClosed = 'not_closed',
  Cancelled = 'cancelled',
  Done = 'done',
}
/**
 * @export
 * @enum {string}
 */
export enum DealReadProducerAgreementStatusEnum {
  Waiting = 'waiting',
  Agreement = 'agreement',
  Disagreement = 'disagreement',
}
/**
 * @export
 * @enum {string}
 */
export enum DealReadWholesalerAgreementStatusEnum {
  Waiting = 'waiting',
  Agreement = 'agreement',
  Disagreement = 'disagreement',
}
/**
 * @export
 * @enum {string}
 */
export enum DealReadInspectionAdjustmentStatusEnum {
  NotNeeded = 'not_needed',
  Waiting = 'waiting',
  Ok = 'ok',
  Ng = 'ng',
}
/**
 * @export
 * @enum {string}
 */
export enum DealReadInspectionResponsibilityEnum {
  NotNeeded = 'not_needed',
  Self = 'self',
  Request = 'request',
}

export function DealReadFromJSON(json: any): DealRead {
  return DealReadFromJSONTyped(json, false)
}

export function DealReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    status: json['status'],
    offerId: json['offer_id'],
    producerAgreementStatus: json['producer_agreement_status'],
    wholesalerAgreementStatus: json['wholesaler_agreement_status'],
    inspectionAdjustmentStatus: json['inspection_adjustment_status'],
    agreementDeadline: new Date(json['agreement_deadline']),
    resubmitRequestedAt: json['resubmit_requested_at'] === null ? null : new Date(json['resubmit_requested_at']),
    wholesaler: DealReadWholesalerFromJSON(json['wholesaler']),
    createdAt: new Date(json['created_at']),
    brandId: json['brand_id'],
    brand: BrandFromJSON(json['brand']),
    gradeId: json['grade_id'],
    grade: GradeFromJSON(json['grade']),
    annumId: json['annum_id'],
    annum: AnnumFromJSON(json['annum']),
    prefectureId: json['prefecture_id'],
    prefecture: PrefectureFromJSON(json['prefecture']),
    regionId: json['region_id'],
    region: RegionFromJSON(json['region']),
    packagingFormId: json['packaging_form_id'],
    packagingForm: PackagingForm1FromJSON(json['packaging_form']),
    tradingSacks: json['trading_sacks'],
    tradingKg: json['trading_kg'],
    packageCount: json['package_count'],
    unitPrice: json['unit_price'],
    offerUnitPrice: json['offer_unit_price'],
    unitPriceAdjustmentInYen: json['unit_price_adjustment_in_yen'],
    storagePeriodId: json['storage_period_id'],
    storagePeriod: StoragePeriod1FromJSON(json['storage_period']),
    tradingVolumeInYen: json['trading_volume_in_yen'],
    gradePenalties: (json['grade_penalties'] as Array<any>).map(AdjustmentDealReadGradePenaltiesFromJSON),
    specialConditionIds: json['special_condition_ids'],
    specialConditions: (json['special_conditions'] as Array<any>).map(SpecialConditionFromJSON),
    specialConditionsMemo: json['special_conditions_memo'],
    expectedHarvestTimeframe:
      json['expected_harvest_timeframe'] === null ? null : new Date(json['expected_harvest_timeframe']),
    inspectionResponsibility: json['inspection_responsibility'],
  }
}

export function DealReadToJSON(value?: DealRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    producer_agreement_status: value.producerAgreementStatus,
    wholesaler_agreement_status: value.wholesalerAgreementStatus,
    inspection_adjustment_status: value.inspectionAdjustmentStatus,
    wholesaler: DealReadWholesalerToJSON(value.wholesaler),
    brand: BrandToJSON(value.brand),
    grade: GradeToJSON(value.grade),
    annum: AnnumToJSON(value.annum),
    prefecture: PrefectureToJSON(value.prefecture),
    region: RegionToJSON(value.region),
    packaging_form_id: value.packagingFormId,
    packaging_form: PackagingForm1ToJSON(value.packagingForm),
    unit_price_adjustment_in_yen: value.unitPriceAdjustmentInYen,
    storage_period_id: value.storagePeriodId,
    storage_period: StoragePeriod1ToJSON(value.storagePeriod),
    expected_harvest_timeframe:
      value.expectedHarvestTimeframe === null ? null : value.expectedHarvestTimeframe.toISOString().substr(0, 10),
    inspection_responsibility: value.inspectionResponsibility,
  }
}
