/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AdjustmentDealReadGradePenalties,
  AdjustmentDealReadGradePenaltiesFromJSON,
  AdjustmentDealReadGradePenaltiesFromJSONTyped,
  AdjustmentDealReadGradePenaltiesToJSON,
  AmendReadSellerHandoverContents,
  AmendReadSellerHandoverContentsFromJSON,
  AmendReadSellerHandoverContentsFromJSONTyped,
  AmendReadSellerHandoverContentsToJSON,
  AmendReadSellerInspectionResults,
  AmendReadSellerInspectionResultsFromJSON,
  AmendReadSellerInspectionResultsFromJSONTyped,
  AmendReadSellerInspectionResultsToJSON,
  AmendReadSellerProducer,
  AmendReadSellerProducerFromJSON,
  AmendReadSellerProducerFromJSONTyped,
  AmendReadSellerProducerToJSON,
  AmendReadSellerWholesaler,
  AmendReadSellerWholesalerFromJSON,
  AmendReadSellerWholesalerFromJSONTyped,
  AmendReadSellerWholesalerToJSON,
  Annum,
  AnnumFromJSON,
  AnnumFromJSONTyped,
  AnnumToJSON,
  Attachment,
  AttachmentFromJSON,
  AttachmentFromJSONTyped,
  AttachmentToJSON,
  Brand,
  BrandFromJSON,
  BrandFromJSONTyped,
  BrandToJSON,
  Grade,
  GradeFromJSON,
  GradeFromJSONTyped,
  GradeToJSON,
  PackagingForm,
  PackagingFormFromJSON,
  PackagingFormFromJSONTyped,
  PackagingFormToJSON,
  Prefecture,
  PrefectureFromJSON,
  PrefectureFromJSONTyped,
  PrefectureToJSON,
  ProducerAddressReadAmend,
  ProducerAddressReadAmendFromJSON,
  ProducerAddressReadAmendFromJSONTyped,
  ProducerAddressReadAmendToJSON,
  ProducerBankAccountReadAmend,
  ProducerBankAccountReadAmendFromJSON,
  ProducerBankAccountReadAmendFromJSONTyped,
  ProducerBankAccountReadAmendToJSON,
  Region,
  RegionFromJSON,
  RegionFromJSONTyped,
  RegionToJSON,
  SpecialCondition,
  SpecialConditionFromJSON,
  SpecialConditionFromJSONTyped,
  SpecialConditionToJSON,
  StoragePeriod,
  StoragePeriodFromJSON,
  StoragePeriodFromJSONTyped,
  StoragePeriodToJSON,
} from './'

/**
 * アメンド
 * @export
 * @interface AmendReadSeller
 */
export interface AmendReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly versionNumber: number
  /**
   * 例外フローフラグ
   * @type {boolean}
   * @memberof AmendReadSeller
   */
  readonly isExceptionFlow: boolean
  /**
   * 現在のステップ（売り手）
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly currentStepSeller: AmendReadSellerCurrentStepSellerEnum
  /**
   * 現在のステップ（買い手）
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly currentStepBuyer: AmendReadSellerCurrentStepBuyerEnum
  /**
   * 現在のステップ（支払い）
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly currentStepPayment: AmendReadSellerCurrentStepPaymentEnum
  /**
   * ディールID
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly dealId: number
  /**
   *
   * @type {Brand}
   * @memberof AmendReadSeller
   */
  brand: Brand
  /**
   *
   * @type {Grade}
   * @memberof AmendReadSeller
   */
  grade: Grade
  /**
   *
   * @type {Annum}
   * @memberof AmendReadSeller
   */
  annum: Annum
  /**
   *
   * @type {Prefecture}
   * @memberof AmendReadSeller
   */
  prefecture: Prefecture
  /**
   *
   * @type {Region}
   * @memberof AmendReadSeller
   */
  region: Region | null
  /**
   *
   * @type {PackagingForm}
   * @memberof AmendReadSeller
   */
  packagingForm: PackagingForm
  /**
   * 納品物の数量
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly packageCount: number
  /**
   * 契約単価（円／俵）
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly unitPrice: number
  /**
   * オファー単価（円／俵）
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly offerUnitPrice: number
  /**
   * 増額される金額（円）
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly unitPriceAdjustmentInYen: number
  /**
   * 等級格差による減額（空可）
   * @type {Array<AdjustmentDealReadGradePenalties>}
   * @memberof AmendReadSeller
   */
  readonly gradePenalties: Array<AdjustmentDealReadGradePenalties>
  /**
   * 特別条件リスト（空可）
   * @type {Array<SpecialCondition>}
   * @memberof AmendReadSeller
   */
  readonly specialConditions: Array<SpecialCondition>
  /**
   * 特別条件メモ
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly specialConditionsMemo: string
  /**
   * 収穫予定時期（画面では「x月y旬」として扱われる、上旬: 日付が1、中旬: 日付が11、下旬: 日付が21、nullは「未定・不明」）
   * @type {Date}
   * @memberof AmendReadSeller
   */
  readonly expectedHarvestTimeframe: Date | null
  /**
   *
   * @type {StoragePeriod}
   * @memberof AmendReadSeller
   */
  storagePeriod: StoragePeriod
  /**
   * 取引金額（税込）
   * @type {number}
   * @memberof AmendReadSeller
   */
  readonly tradingVolumeInYen: number
  /**
   * 引渡内容
   * @type {Array<AmendReadSellerHandoverContents>}
   * @memberof AmendReadSeller
   */
  readonly handoverContents: Array<AmendReadSellerHandoverContents>
  /**
   * 引渡合計取引金額
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly handoverVolumeInYen: string
  /**
   * 引渡可能日
   * @type {Date}
   * @memberof AmendReadSeller
   */
  readonly handoverPossibleDate: Date | null
  /**
   * 土日・祝日に引渡可能フラグ
   * @type {boolean}
   * @memberof AmendReadSeller
   */
  readonly canHandoverOnHoliday: boolean | null
  /**
   * 運送手配時の注意点
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly handoverMemo: string | null
  /**
   *
   * @type {ProducerAddressReadAmend}
   * @memberof AmendReadSeller
   */
  handoverAddress: ProducerAddressReadAmend | null
  /**
   * 引渡期限
   *
   * 引渡可能日と保管期間から自動算出される
   *
   * 土日・祝日は考慮されない
   * @type {Date}
   * @memberof AmendReadSeller
   */
  readonly handoverDeadlineDate: Date | null
  /**
   * 検査結果
   * @type {Array<AmendReadSellerInspectionResults>}
   * @memberof AmendReadSeller
   */
  readonly inspectionResults: Array<AmendReadSellerInspectionResults> | null
  /**
   * 検査結果を裏付ける資料（添付ファイルUUIDリスト）
   * @type {Array<string>}
   * @memberof AmendReadSeller
   */
  inspectionResultsAttachmentUuids?: Array<string>
  /**
   * 検査結果を裏付ける資料
   * @type {Array<Attachment>}
   * @memberof AmendReadSeller
   */
  readonly inspectionResultsAttachments: Array<Attachment> | null
  /**
   * 検査結果メモ
   * @type {string}
   * @memberof AmendReadSeller
   */
  readonly inspectionResultsMemo: string | null
  /**
   *
   * @type {ProducerBankAccountReadAmend}
   * @memberof AmendReadSeller
   */
  paymentBankAccount: ProducerBankAccountReadAmend | null
  /**
   *
   * @type {AmendReadSellerWholesaler}
   * @memberof AmendReadSeller
   */
  wholesaler: AmendReadSellerWholesaler
  /**
   *
   * @type {AmendReadSellerProducer}
   * @memberof AmendReadSeller
   */
  producer: AmendReadSellerProducer
}

/**
 * @export
 * @enum {string}
 */
export enum AmendReadSellerCurrentStepSellerEnum {
  WaitingForHarvestTimeframe = 'waiting_for_harvest_timeframe',
  WaitingForHandoverInfo = 'waiting_for_handover_info',
  WaitingForInspectionResults = 'waiting_for_inspection_results',
  Done = 'done',
}
/**
 * @export
 * @enum {string}
 */
export enum AmendReadSellerCurrentStepBuyerEnum {
  WaitingForHandoverInfo = 'waiting_for_handover_info',
  WaitingForDeliveryCreation = 'waiting_for_delivery_creation',
  WaitingForAfterDeliveryInspection = 'waiting_for_after_delivery_inspection',
  Done = 'done',
}
/**
 * @export
 * @enum {string}
 */
export enum AmendReadSellerCurrentStepPaymentEnum {
  WaitingForDelivery = 'waiting_for_delivery',
  WaitingForPayment = 'waiting_for_payment',
  Done = 'done',
}

export function AmendReadSellerFromJSON(json: any): AmendReadSeller {
  return AmendReadSellerFromJSONTyped(json, false)
}

export function AmendReadSellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AmendReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    isExceptionFlow: json['is_exception_flow'],
    currentStepSeller: json['current_step_seller'],
    currentStepBuyer: json['current_step_buyer'],
    currentStepPayment: json['current_step_payment'],
    dealId: json['deal_id'],
    brand: BrandFromJSON(json['brand']),
    grade: GradeFromJSON(json['grade']),
    annum: AnnumFromJSON(json['annum']),
    prefecture: PrefectureFromJSON(json['prefecture']),
    region: RegionFromJSON(json['region']),
    packagingForm: PackagingFormFromJSON(json['packaging_form']),
    packageCount: json['package_count'],
    unitPrice: json['unit_price'],
    offerUnitPrice: json['offer_unit_price'],
    unitPriceAdjustmentInYen: json['unit_price_adjustment_in_yen'],
    gradePenalties: (json['grade_penalties'] as Array<any>).map(AdjustmentDealReadGradePenaltiesFromJSON),
    specialConditions: (json['special_conditions'] as Array<any>).map(SpecialConditionFromJSON),
    specialConditionsMemo: json['special_conditions_memo'],
    expectedHarvestTimeframe:
      json['expected_harvest_timeframe'] === null ? null : new Date(json['expected_harvest_timeframe']),
    storagePeriod: StoragePeriodFromJSON(json['storage_period']),
    tradingVolumeInYen: json['trading_volume_in_yen'],
    handoverContents: (json['handover_contents'] as Array<any>).map(AmendReadSellerHandoverContentsFromJSON),
    handoverVolumeInYen: json['handover_volume_in_yen'],
    handoverPossibleDate: json['handover_possible_date'] === null ? null : new Date(json['handover_possible_date']),
    canHandoverOnHoliday: json['can_handover_on_holiday'],
    handoverMemo: json['handover_memo'],
    handoverAddress: ProducerAddressReadAmendFromJSON(json['handover_address']),
    handoverDeadlineDate: json['handover_deadline_date'] === null ? null : new Date(json['handover_deadline_date']),
    inspectionResults:
      json['inspection_results'] === null
        ? null
        : (json['inspection_results'] as Array<any>).map(AmendReadSellerInspectionResultsFromJSON),
    inspectionResultsAttachmentUuids: !exists(json, 'inspection_results_attachment_uuids')
      ? undefined
      : json['inspection_results_attachment_uuids'],
    inspectionResultsAttachments:
      json['inspection_results_attachments'] === null
        ? null
        : (json['inspection_results_attachments'] as Array<any>).map(AttachmentFromJSON),
    inspectionResultsMemo: json['inspection_results_memo'],
    paymentBankAccount: ProducerBankAccountReadAmendFromJSON(json['payment_bank_account']),
    wholesaler: AmendReadSellerWholesalerFromJSON(json['wholesaler']),
    producer: AmendReadSellerProducerFromJSON(json['producer']),
  }
}

export function AmendReadSellerToJSON(value?: AmendReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand: BrandToJSON(value.brand),
    grade: GradeToJSON(value.grade),
    annum: AnnumToJSON(value.annum),
    prefecture: PrefectureToJSON(value.prefecture),
    region: RegionToJSON(value.region),
    packaging_form: PackagingFormToJSON(value.packagingForm),
    storage_period: StoragePeriodToJSON(value.storagePeriod),
    handover_address: ProducerAddressReadAmendToJSON(value.handoverAddress),
    inspection_results_attachment_uuids: value.inspectionResultsAttachmentUuids,
    payment_bank_account: ProducerBankAccountReadAmendToJSON(value.paymentBankAccount),
    wholesaler: AmendReadSellerWholesalerToJSON(value.wholesaler),
    producer: AmendReadSellerProducerToJSON(value.producer),
  }
}
