/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AdjustmentDealReadGradePenalties,
  AdjustmentDealReadGradePenaltiesFromJSON,
  AdjustmentDealReadGradePenaltiesFromJSONTyped,
  AdjustmentDealReadGradePenaltiesToJSON,
  AdjustmentDealReadWholesaler,
  AdjustmentDealReadWholesalerFromJSON,
  AdjustmentDealReadWholesalerFromJSONTyped,
  AdjustmentDealReadWholesalerToJSON,
  Annum,
  AnnumFromJSON,
  AnnumFromJSONTyped,
  AnnumToJSON,
  Brand,
  BrandFromJSON,
  BrandFromJSONTyped,
  BrandToJSON,
  Grade,
  GradeFromJSON,
  GradeFromJSONTyped,
  GradeToJSON,
  PackagingForm,
  PackagingFormFromJSON,
  PackagingFormFromJSONTyped,
  PackagingFormToJSON,
  Prefecture,
  PrefectureFromJSON,
  PrefectureFromJSONTyped,
  PrefectureToJSON,
  Region,
  RegionFromJSON,
  RegionFromJSONTyped,
  RegionToJSON,
  SpecialCondition,
  SpecialConditionFromJSON,
  SpecialConditionFromJSONTyped,
  SpecialConditionToJSON,
  StoragePeriod,
  StoragePeriodFromJSON,
  StoragePeriodFromJSONTyped,
  StoragePeriodToJSON,
} from './'

/**
 * 取引案件（要調整）（Read）
 * @export
 * @interface AdjustmentDealRead
 */
export interface AdjustmentDealRead {
  /**
   * ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly versionNumber: number
  /**
   * ディールステータス
   *
   * * "closed": 成約済
   * * "canceled": 取消済
   * * "done": 取引案件へ移行完了
   * @type {string}
   * @memberof AdjustmentDealRead
   */
  readonly status: AdjustmentDealReadStatusEnum
  /**
   *
   * @type {AdjustmentDealReadWholesaler}
   * @memberof AdjustmentDealRead
   */
  wholesaler: AdjustmentDealReadWholesaler
  /**
   * ディール生成日時
   * @type {Date}
   * @memberof AdjustmentDealRead
   */
  readonly createdAt: Date
  /**
   * 年産ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly annumId: number
  /**
   *
   * @type {Annum}
   * @memberof AdjustmentDealRead
   */
  annum: Annum
  /**
   * 都道府県ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly prefectureId: number
  /**
   *
   * @type {Prefecture}
   * @memberof AdjustmentDealRead
   */
  prefecture: Prefecture
  /**
   * 地域ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly regionId: number | null
  /**
   *
   * @type {Region}
   * @memberof AdjustmentDealRead
   */
  region: Region | null
  /**
   * 銘柄ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly brandId: number
  /**
   *
   * @type {Brand}
   * @memberof AdjustmentDealRead
   */
  brand: Brand
  /**
   * 等級ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly gradeId: number
  /**
   *
   * @type {Grade}
   * @memberof AdjustmentDealRead
   */
  grade: Grade
  /**
   * 包装形態ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly packagingFormId: number
  /**
   *
   * @type {PackagingForm}
   * @memberof AdjustmentDealRead
   */
  packagingForm: PackagingForm
  /**
   * 取引俵数
   * @type {string}
   * @memberof AdjustmentDealRead
   */
  readonly tradingSacks: string
  /**
   * 取引重量（kg）
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly tradingKg: number
  /**
   * 納品物の数量
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly packageCount: number
  /**
   * ディール単価（円／俵）
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly unitPrice: number
  /**
   * 検査後保管可能期間ID
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly storagePeriodId: number
  /**
   *
   * @type {StoragePeriod}
   * @memberof AdjustmentDealRead
   */
  storagePeriod: StoragePeriod
  /**
   * 取引金額（税込）
   * @type {number}
   * @memberof AdjustmentDealRead
   */
  readonly tradingVolumeInYen: number
  /**
   * 等級格差による減額（空可）
   * @type {Array<AdjustmentDealReadGradePenalties>}
   * @memberof AdjustmentDealRead
   */
  readonly gradePenalties: Array<AdjustmentDealReadGradePenalties>
  /**
   * 特別条件IDリスト（空可）
   * @type {Array<number>}
   * @memberof AdjustmentDealRead
   */
  readonly specialConditionIds: Array<number>
  /**
   * 特別条件リスト（空可）
   * @type {Array<SpecialCondition>}
   * @memberof AdjustmentDealRead
   */
  readonly specialConditions: Array<SpecialCondition>
  /**
   * 特別条件メモ
   * @type {string}
   * @memberof AdjustmentDealRead
   */
  readonly specialConditionsMemo: string
  /**
   * 要調整メモ（売り手・買い手が共有する情報）
   * @type {string}
   * @memberof AdjustmentDealRead
   */
  readonly adjustmentMemo: string
}

/**
 * @export
 * @enum {string}
 */
export enum AdjustmentDealReadStatusEnum {
  Closed = 'closed',
  Canceled = 'canceled',
  Done = 'done',
}

export function AdjustmentDealReadFromJSON(json: any): AdjustmentDealRead {
  return AdjustmentDealReadFromJSONTyped(json, false)
}

export function AdjustmentDealReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdjustmentDealRead {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    status: json['status'],
    wholesaler: AdjustmentDealReadWholesalerFromJSON(json['wholesaler']),
    createdAt: new Date(json['created_at']),
    annumId: json['annum_id'],
    annum: AnnumFromJSON(json['annum']),
    prefectureId: json['prefecture_id'],
    prefecture: PrefectureFromJSON(json['prefecture']),
    regionId: json['region_id'],
    region: RegionFromJSON(json['region']),
    brandId: json['brand_id'],
    brand: BrandFromJSON(json['brand']),
    gradeId: json['grade_id'],
    grade: GradeFromJSON(json['grade']),
    packagingFormId: json['packaging_form_id'],
    packagingForm: PackagingFormFromJSON(json['packaging_form']),
    tradingSacks: json['trading_sacks'],
    tradingKg: json['trading_kg'],
    packageCount: json['package_count'],
    unitPrice: json['unit_price'],
    storagePeriodId: json['storage_period_id'],
    storagePeriod: StoragePeriodFromJSON(json['storage_period']),
    tradingVolumeInYen: json['trading_volume_in_yen'],
    gradePenalties: (json['grade_penalties'] as Array<any>).map(AdjustmentDealReadGradePenaltiesFromJSON),
    specialConditionIds: json['special_condition_ids'],
    specialConditions: (json['special_conditions'] as Array<any>).map(SpecialConditionFromJSON),
    specialConditionsMemo: json['special_conditions_memo'],
    adjustmentMemo: json['adjustment_memo'],
  }
}

export function AdjustmentDealReadToJSON(value?: AdjustmentDealRead | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    wholesaler: AdjustmentDealReadWholesalerToJSON(value.wholesaler),
    annum: AnnumToJSON(value.annum),
    prefecture: PrefectureToJSON(value.prefecture),
    region: RegionToJSON(value.region),
    brand: BrandToJSON(value.brand),
    grade: GradeToJSON(value.grade),
    packaging_form: PackagingFormToJSON(value.packagingForm),
    storage_period: StoragePeriodToJSON(value.storagePeriod),
  }
}
