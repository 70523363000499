/* tslint:disable */
/* eslint-disable */
/**
 * seller
 * アグリノート米市場 Seller API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: mazhulin.d@water-cell.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AdjustmentDealReadGradePenalties,
  AdjustmentDealReadGradePenaltiesFromJSON,
  AdjustmentDealReadGradePenaltiesFromJSONTyped,
  AdjustmentDealReadGradePenaltiesToJSON,
  AmendReadSellerInspectionResults,
  AmendReadSellerInspectionResultsFromJSON,
  AmendReadSellerInspectionResultsFromJSONTyped,
  AmendReadSellerInspectionResultsToJSON,
  Attachment,
  AttachmentFromJSON,
  AttachmentFromJSONTyped,
  AttachmentToJSON,
  Brand,
  BrandFromJSON,
  BrandFromJSONTyped,
  BrandToJSON,
  DealReadWholesaler,
  DealReadWholesalerFromJSON,
  DealReadWholesalerFromJSONTyped,
  DealReadWholesalerToJSON,
  Grade,
  GradeFromJSON,
  GradeFromJSONTyped,
  GradeToJSON,
  PackagingForm1,
  PackagingForm1FromJSON,
  PackagingForm1FromJSONTyped,
  PackagingForm1ToJSON,
  Prefecture,
  PrefectureFromJSON,
  PrefectureFromJSONTyped,
  PrefectureToJSON,
  ProducerAddressReadAmend,
  ProducerAddressReadAmendFromJSON,
  ProducerAddressReadAmendFromJSONTyped,
  ProducerAddressReadAmendToJSON,
  ProducerBankAccountReadAmend,
  ProducerBankAccountReadAmendFromJSON,
  ProducerBankAccountReadAmendFromJSONTyped,
  ProducerBankAccountReadAmendToJSON,
  Region,
  RegionFromJSON,
  RegionFromJSONTyped,
  RegionToJSON,
  SpotContractAnnum,
  SpotContractAnnumFromJSON,
  SpotContractAnnumFromJSONTyped,
  SpotContractAnnumToJSON,
  SpotContractSpecialCondition,
  SpotContractSpecialConditionFromJSON,
  SpotContractSpecialConditionFromJSONTyped,
  SpotContractSpecialConditionToJSON,
  SpotContractStoragePeriod1,
  SpotContractStoragePeriod1FromJSON,
  SpotContractStoragePeriod1FromJSONTyped,
  SpotContractStoragePeriod1ToJSON,
} from './'

/**
 * [現物契約] ディール（Read）
 * @export
 * @interface SpotContractDealReadSeller
 */
export interface SpotContractDealReadSeller {
  /**
   * ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly id: number
  /**
   * レコードのバージョン番号
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly versionNumber: number
  /**
   * ディールステータス
   *
   * * "closed": 成約済
   * * "provisional": 仮成約
   * * "not_closed": 不成約
   * * "cancelled": 取消済
   * * "done": 完了
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  status: SpotContractDealReadSellerStatusEnum
  /**
   * 生産者承諾状況（"waiting": 要承諾、"agreement": 承諾済、"disagreement": 不承諾）
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  producerAgreementStatus: SpotContractDealReadSellerProducerAgreementStatusEnum
  /**
   * 卸事業者承諾状況（"waiting": 要承諾、"agreement": 承諾済、"disagreement": 不承諾）
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  wholesalerAgreementStatus: SpotContractDealReadSellerWholesalerAgreementStatusEnum
  /**
   * 承諾期限日（ディール登録時と再提示時に設定される）
   * @type {Date}
   * @memberof SpotContractDealReadSeller
   */
  readonly agreementDeadline: Date
  /**
   *
   * @type {DealReadWholesaler}
   * @memberof SpotContractDealReadSeller
   */
  wholesaler: DealReadWholesaler
  /**
   * ディール生成日時
   * @type {Date}
   * @memberof SpotContractDealReadSeller
   */
  readonly createdAt: Date
  /**
   * 決定日（売り手・買い手の承諾日）
   * @type {Date}
   * @memberof SpotContractDealReadSeller
   */
  readonly decisionDate: Date | null
  /**
   * 銘柄ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly brandId: number
  /**
   *
   * @type {Brand}
   * @memberof SpotContractDealReadSeller
   */
  brand: Brand
  /**
   * 等級ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly gradeId: number
  /**
   *
   * @type {Grade}
   * @memberof SpotContractDealReadSeller
   */
  grade: Grade
  /**
   * [現物契約] 年産ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly annumId: number
  /**
   *
   * @type {SpotContractAnnum}
   * @memberof SpotContractDealReadSeller
   */
  annum: SpotContractAnnum
  /**
   * 都道府県ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly prefectureId: number
  /**
   *
   * @type {Prefecture}
   * @memberof SpotContractDealReadSeller
   */
  prefecture: Prefecture
  /**
   * 地域ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly regionId: number | null
  /**
   *
   * @type {Region}
   * @memberof SpotContractDealReadSeller
   */
  region: Region | null
  /**
   * 納品物の梱包形態ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  packagingFormId: number
  /**
   *
   * @type {PackagingForm1}
   * @memberof SpotContractDealReadSeller
   */
  packagingForm: PackagingForm1
  /**
   * 取引俵数
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  readonly tradingSacks: string
  /**
   * 取引重量（kg）
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly tradingKg: number
  /**
   * 納品物の数量
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly packageCount: number
  /**
   * ディール単価（円／俵）
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly unitPrice: number
  /**
   * オファー単価（円／俵）
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly offerUnitPrice: number
  /**
   * 増額される金額（円）
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  unitPriceAdjustmentInYen: number
  /**
   * [現物契約] 成約後保管可能期間ID
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  storagePeriodId: number
  /**
   *
   * @type {SpotContractStoragePeriod1}
   * @memberof SpotContractDealReadSeller
   */
  storagePeriod: SpotContractStoragePeriod1
  /**
   * 取引金額（税込）
   * @type {number}
   * @memberof SpotContractDealReadSeller
   */
  readonly tradingVolumeInYen: number
  /**
   * 等級格差による減額（空可）
   * @type {Array<AdjustmentDealReadGradePenalties>}
   * @memberof SpotContractDealReadSeller
   */
  readonly gradePenalties: Array<AdjustmentDealReadGradePenalties>
  /**
   * [現物契約] 特別条件IDリスト（空可）
   * @type {Array<number>}
   * @memberof SpotContractDealReadSeller
   */
  readonly specialConditionIds: Array<number>
  /**
   * [現物契約] 特別条件リスト（空可）
   * @type {Array<SpotContractSpecialCondition>}
   * @memberof SpotContractDealReadSeller
   */
  readonly specialConditions: Array<SpotContractSpecialCondition>
  /**
   * 特別条件メモ
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  readonly specialConditionsMemo: string
  /**
   * 土日・祝日に引渡可能フラグ
   * @type {boolean}
   * @memberof SpotContractDealReadSeller
   */
  readonly canHandoverOnHoliday: boolean | null
  /**
   * 運送手配時の注意点
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  readonly handoverMemo: string | null
  /**
   *
   * @type {ProducerAddressReadAmend}
   * @memberof SpotContractDealReadSeller
   */
  handoverAddress: ProducerAddressReadAmend | null
  /**
   * 検査結果
   * @type {Array<AmendReadSellerInspectionResults>}
   * @memberof SpotContractDealReadSeller
   */
  readonly inspectionResults: Array<AmendReadSellerInspectionResults> | null
  /**
   * 検査結果を裏付ける資料
   * @type {Array<Attachment>}
   * @memberof SpotContractDealReadSeller
   */
  readonly inspectionResultsAttachments: Array<Attachment> | null
  /**
   * 検査結果メモ
   * @type {string}
   * @memberof SpotContractDealReadSeller
   */
  readonly inspectionResultsMemo: string | null
  /**
   *
   * @type {ProducerBankAccountReadAmend}
   * @memberof SpotContractDealReadSeller
   */
  paymentBankAccount: ProducerBankAccountReadAmend | null
}

/**
 * @export
 * @enum {string}
 */
export enum SpotContractDealReadSellerStatusEnum {
  Closed = 'closed',
  Provisional = 'provisional',
  NotClosed = 'not_closed',
  Cancelled = 'cancelled',
  Done = 'done',
}
/**
 * @export
 * @enum {string}
 */
export enum SpotContractDealReadSellerProducerAgreementStatusEnum {
  Waiting = 'waiting',
  Agreement = 'agreement',
  Disagreement = 'disagreement',
}
/**
 * @export
 * @enum {string}
 */
export enum SpotContractDealReadSellerWholesalerAgreementStatusEnum {
  Waiting = 'waiting',
  Agreement = 'agreement',
  Disagreement = 'disagreement',
}

export function SpotContractDealReadSellerFromJSON(json: any): SpotContractDealReadSeller {
  return SpotContractDealReadSellerFromJSONTyped(json, false)
}

export function SpotContractDealReadSellerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpotContractDealReadSeller {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    versionNumber: json['version_number'],
    status: json['status'],
    producerAgreementStatus: json['producer_agreement_status'],
    wholesalerAgreementStatus: json['wholesaler_agreement_status'],
    agreementDeadline: new Date(json['agreement_deadline']),
    wholesaler: DealReadWholesalerFromJSON(json['wholesaler']),
    createdAt: new Date(json['created_at']),
    decisionDate: json['decision_date'] === null ? null : new Date(json['decision_date']),
    brandId: json['brand_id'],
    brand: BrandFromJSON(json['brand']),
    gradeId: json['grade_id'],
    grade: GradeFromJSON(json['grade']),
    annumId: json['annum_id'],
    annum: SpotContractAnnumFromJSON(json['annum']),
    prefectureId: json['prefecture_id'],
    prefecture: PrefectureFromJSON(json['prefecture']),
    regionId: json['region_id'],
    region: RegionFromJSON(json['region']),
    packagingFormId: json['packaging_form_id'],
    packagingForm: PackagingForm1FromJSON(json['packaging_form']),
    tradingSacks: json['trading_sacks'],
    tradingKg: json['trading_kg'],
    packageCount: json['package_count'],
    unitPrice: json['unit_price'],
    offerUnitPrice: json['offer_unit_price'],
    unitPriceAdjustmentInYen: json['unit_price_adjustment_in_yen'],
    storagePeriodId: json['storage_period_id'],
    storagePeriod: SpotContractStoragePeriod1FromJSON(json['storage_period']),
    tradingVolumeInYen: json['trading_volume_in_yen'],
    gradePenalties: (json['grade_penalties'] as Array<any>).map(AdjustmentDealReadGradePenaltiesFromJSON),
    specialConditionIds: json['special_condition_ids'],
    specialConditions: (json['special_conditions'] as Array<any>).map(SpotContractSpecialConditionFromJSON),
    specialConditionsMemo: json['special_conditions_memo'],
    canHandoverOnHoliday: json['can_handover_on_holiday'],
    handoverMemo: json['handover_memo'],
    handoverAddress: ProducerAddressReadAmendFromJSON(json['handover_address']),
    inspectionResults:
      json['inspection_results'] === null
        ? null
        : (json['inspection_results'] as Array<any>).map(AmendReadSellerInspectionResultsFromJSON),
    inspectionResultsAttachments:
      json['inspection_results_attachments'] === null
        ? null
        : (json['inspection_results_attachments'] as Array<any>).map(AttachmentFromJSON),
    inspectionResultsMemo: json['inspection_results_memo'],
    paymentBankAccount: ProducerBankAccountReadAmendFromJSON(json['payment_bank_account']),
  }
}

export function SpotContractDealReadSellerToJSON(value?: SpotContractDealReadSeller | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    producer_agreement_status: value.producerAgreementStatus,
    wholesaler_agreement_status: value.wholesalerAgreementStatus,
    wholesaler: DealReadWholesalerToJSON(value.wholesaler),
    brand: BrandToJSON(value.brand),
    grade: GradeToJSON(value.grade),
    annum: SpotContractAnnumToJSON(value.annum),
    prefecture: PrefectureToJSON(value.prefecture),
    region: RegionToJSON(value.region),
    packaging_form_id: value.packagingFormId,
    packaging_form: PackagingForm1ToJSON(value.packagingForm),
    unit_price_adjustment_in_yen: value.unitPriceAdjustmentInYen,
    storage_period_id: value.storagePeriodId,
    storage_period: SpotContractStoragePeriod1ToJSON(value.storagePeriod),
    handover_address: ProducerAddressReadAmendToJSON(value.handoverAddress),
    payment_bank_account: ProducerBankAccountReadAmendToJSON(value.paymentBankAccount),
  }
}
